/*jshint -W033 */
/*jshint esversion: 6 */
// import { initializeApp } from "https://www.gstatic.com/firebasejs/9.4.1/firebase-app.js";
// import { getAuth, onAuthStateChanged,GoogleAuthProvider, signInWithRedirect, getRedirectResult, signInWithPopup, signOut } from "https://www.gstatic.com/firebasejs/9.4.1/firebase-auth.js";
import { initializeApp } from './firebase-app.js'
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
  signOut
} from './firebase-auth.js'
const firebaseConfig = {
  apiKey: 'AIzaSyDKvhZNOKpyqSgEffYGPoCnNwltk3ox72M',
  authDomain: 'resumai-ca3b8.firebaseapp.com',
  projectId: 'resumai-ca3b8',
  storageBucket: 'resumai-ca3b8.appspot.com',
  messagingSenderId: '292926657796',
  appId: '1:292926657796:web:f191fcb380890129992c51',
  measurementId: 'G-C31P3VM5SC'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const provider = new GoogleAuthProvider(app)

auth.onAuthStateChanged(function (user) {
  if (user) {
    user.getIdToken().then(function (data) {
      sessionStorage.setItem('uid', user.uid)
      sessionStorage.setItem('email', user.email)
      sessionStorage.setItem('jwtBody', data)
      $('#modal_login').iziModal('close')
    })
  }
})



window.init_sign_out = function () {
  signOut(auth)
    .then(() => {
      sessionStorage.clear()
      localStorage.clear()
      location.reload()
    })
    .catch(error => {
      // An error happened.
    })
}

// Paddle.Environment.set('sandbox');
// Paddle.Setup({ vendor: 11366 });
